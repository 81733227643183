<template>
  <b-row class="settings my-profile" v-if="user != null">
    <b-col>
      <PageHeader :title="$t('general.myProfile.title')" />

      <b-row>
        <div class="bg-light cover w-100 position-relative">
          <b-img :src="bgPreview" v-if="user.cover" />
          <div class="buttons d-flex align-items-center">
            <button
              type="button"
              class="p-0 mx-2 align-items-center"
              @click.prevent="clck('cover')"
            >
              <CIcon
                name="camera"
                path="my-profile"
                class="my-profile__icon my-profile__icon--camera"
              />
            </button>

            <button
              type="button"
              class="p-0 mx-2 align-items-center"
              @click.prevent="del('cover')"
            >
              <CIcon
                name="cross"
                path="my-profile"
                class="my-profile__icon my-profile__icon--cross"
              />
            </button>
          </div>
        </div>

        <div
          class="avatar position-relative ml-3 rounded-circle profile-avatar"
        >
          <b-avatar
            :src="avatarPreview"
            text=" "
            size="80px"
            class="border-0"
          />
          <div class="buttons d-flex justify-content-center">
            <button
              type="button"
              class="p-0 align-items-center"
              @click.prevent="clck('avatar')"
            >
              <CIcon
                name="camera"
                path="my-profile"
                class="my-profile__icon my-profile__icon--camera my-profile__icon--camera--2"
              />
            </button>

            <button
              type="button"
              class="p-0 align-items-center"
              @click.prevent="del('avatar')"
            >
              <CIcon
                name="cross"
                path="my-profile"
                class="my-profile__icon my-profile__icon--cross my-profile__icon--cross--2"
              />
            </button>
          </div>
        </div>
      </b-row>

      <b-row>
        <form @submit="saveSettings" class="w-100 mx-3 mt-3">
          <div class="my-profile__input">
            <CInput
              type="text"
              name="username"
              theme="settings"
              class="my-profile__field"
              v-model="user.username"
              :label="$t('general.myProfile.input.username')"
              :rules="'required'"
            >
              <template #appendAfter>
                <transition name="fade" mode="out-in">
                  <CIcon
                    name="check"
                    class="my-profile__icon my-profile__icon--check"
                    v-if="user.username"
                  />
                  <CIcon
                    name="false"
                    class="my-profile__icon my-profile__icon--false"
                    v-else
                  />
                </transition>
              </template>

              <template #appendAddition>
                <span class="my-profile__counter">
                  <span class="my-profile__font my-profile__font--addition">
                    {{ appUrl }}/{{ user.username }}
                  </span>
                </span>
              </template>
            </CInput>
          </div>

          <div class="my-profile__input">
            <CInput
              type="text"
              name="name"
              theme="settings"
              class="my-profile__field my-profile__field--name"
              v-model="user.name"
              :label="$t('general.myProfile.input.name')"
              :rules="'required|max:40'"
            >
              <template #appendAfter>
                <transition name="fade" mode="out-in">
                  <CIcon
                    name="check"
                    class="my-profile__icon my-profile__icon--check"
                    v-if="user.name"
                  />
                  <CIcon
                    name="false"
                    class="my-profile__icon my-profile__icon--false"
                    v-else
                  />
                </transition>
              </template>

              <template #appendAddition>
                <AppCount
                  class="my-profile__counter"
                  theme="light"
                  :amount="user.name.length"
                  max="40"
                />
              </template>
            </CInput>
          </div>

          <div class="my-profile__textarea">
            <Textarea
              name="bio"
              theme="settings"
              v-model="user.bio"
              :label="$t('general.myProfile.input.bio')"
              :rules="'required|max:1000'"
            />

            <AppCount
              v-if="user.bio"
              class="my-profile__counter"
              theme="light"
              :amount="user.bio.length"
              max="1000"
            />
          </div>

          <ValidationProvider
            rules="required"
            name="location"
            ref="location"
            mode="eager"
            v-slot="{ errors }"
          >
            <div class="v-select-profile mt-4">
              <v-select
                class="vs-theme-app"
                v-model="location"
                name="location"
                label="address"
                placeholder="Location"
                :options="locationList"
                :reduce="(option) => option"
                @search="onLocationInput"
              />

              <span
                class="v-select-placeholder"
                :class="{ 'v-select-placeholder--active': location }"
              >
                Location
              </span>

              <small class="text-danger">
                {{ errors[0] }}
              </small>
            </div>
          </ValidationProvider>


          <div class="my-profile__input my-profile__input--bottom">
            <CInput
              type="text"
              name="website"
              theme="settings"
              class="my-profile__field my-profile__field--bio-link"
              v-model="user.website"
              :label="$t('general.myProfile.input.bioLink')"
              :rules="'max:100'"
            >
              <template #appendAddition>
                <AppCount
                  v-if="user.website"
                  class="my-profile__counter"
                  theme="light"
                  :amount="user.website.length"
                  max="100"
                />
              </template>
            </CInput>
          </div>

          <div class="my-profile__button-wrapper">
            <button
              type="submit"
              @click.prevent="saveSettings"
              class="base-button my-profile__button"
            >
              <span class="base-button__font">
                {{ $t("general.myProfile.save") }}
              </span>
            </button>
          </div>
        </form>
      </b-row>
    </b-col>

    <input type="file" ref="avatar" hidden @change="upload('avatar')" />
    <input type="file" ref="cover" hidden @change="upload('cover')" />
  </b-row>
</template>

<script>
import User from "@/components/models/User";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import location from "@/mixins/location";

import PageHeader from "@/layout/PageHeader.vue";
import CIcon from "@/features/ui/CIcon.vue";
import CInput from "@/features/ui/СInput.vue";
import Textarea from "@/features/ui/CTextarea.vue";
import AppCount from "@/features/components/AppCounter.vue";
import vSelect from "vue-select";
import { ValidationProvider } from "vee-validate";

export default {
  name: "PageMyProfile",
  mixins: [waitRequest, validationError, location],
  components: {
    ValidationProvider,
    vSelect,
    AppCount,
    Textarea,
    CInput,
    CIcon,
    PageHeader,
  },
  data() {
    return {
      user: null,
      location: null,
      rnd: Math.random(),
    };
  },

  computed: {
    bgPreview() {
      if (!this.user.cover) return "/mock/header.webp";

      return this.user.cover + "?rnd=" + this.rnd;
    },

    avatarPreview() {
      if (!this.user.avatar) return "/mock/PFP.webp";

      return this.user.avatar + "?rnd=" + this.rnd;
    },

    appUrl() {
      return process.env.VUE_APP_APP_URL;
    },
  },

  mounted() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$get(
          "/auth/me",
          (data) => {
            this.user = new User(data);

            const { address, lat, lon } = this.user.location;

            this.location = {
              address,
              lat,
              lon,
            };
          },
          (errors) => {
            console.log(errors);
          },
          this.checkErrors
        );
      });
    },
    async saveSettings() {
      const provider = this.$refs.location;
      const location = await provider.validate();

      const isValid = await this.$validator.validateAll();
      if (!isValid || !location.valid || this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$post(
          "/profile",
          {
            username: this.user.username,
            name: this.user.name,
            bio: this.user.bio,
            website: this.user.website,
            location: this.location,
          },

          (data) => {
            this.user = new User(data);
            this.$saveUser(data);
            this.$bvToast.toast(this.$t("general.settings-saved"), {
              autoHideDelay: 2000,
              title: this.$t("general.success"),
              solid: true,
              toaster: "b-toaster-bottom-left flow-toast",
            });
          },
          (errors) => {
            console.log("error", errors);
          },
          this.checkErrors
        );
      }).catch(this.checkErrors);
    },

    clck(type) {
      this.$refs[type].click();
    },

    upload(type) {
      let formData = new FormData();
      formData.append("image", this.$refs[type].files[0]);
      this.$post(
        "/profile/image/" + type,
        formData,
        (data) => {
          this.user = new User(data);
          this.$saveUser(data);
          this.rnd = Math.random();
        },
        (errors) => {
          console.log(errors);
        }
      );
    },

    del(type) {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$post(
          "/profile/image/" + type,
          {},
          (data) => {
            this.user = new User(data);
            this.$saveUser(data);
            this.rnd = Math.random();
          },
          (errors) => {
            console.log(errors);
          },
          this.checkErrors
        );
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vendors/_variables";
@import "@/assets/scss/vendors/v-select";

.settings {
  .cover,
  .avatar {
    z-index: 2;
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      content: "";
      z-index: 3;
    }
  }
  .cover {
    height: 145px;
    background-size: cover;
    background-image: url("/mock/header.webp");
    background-position: center center;

    img {
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  .buttons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
  }
  .avatar {
    margin-top: -50px;
    z-index: 3;
    &:after {
      border-radius: 50%;
    }
  }
}

.profile-avatar {
  position: relative;
  border-radius: 50%;
  margin-left: em(17);
  border: em(3) solid $white;
  margin-top: em(-43);
  z-index: 3;
}

.base-button {
  $parent: &;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 24) em(15, 24);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.my-profile {
  &__settings {
    border-bottom: 1px solid $app-gray-2;
  }

  &__button {
    margin-top: em(17);
    margin-left: auto;
  }

  &__button-wrapper {
    display: flex;
  }

  &__input {
    position: relative;

    &--bottom {
      margin-left: em(-15);
      margin-right: em(-15);
      padding: 0 em(15) em(20);
      border-bottom: 1px solid $app-gray-2;
    }
  }

  &__textarea {
    position: relative;
    width: 100%;
    min-height: em(78);
    margin-top: em(12);
    //padding: em(16);
  }

  &__counter {
    position: absolute;
    top: 100%;
    //bottom: em(16);
    right: em(0);
  }

  &__field {
    &--username {
    }

    &--name {
      margin-top: em(13);
    }

    &--location {
      margin-top: em(35);
    }

    &--bio-link {
      margin-top: em(15);
    }
  }

  &__font {
    &--addition {
      font-family: $font-default;
      font-size: em(12);
      color: $app-font-color-placeholder;
    }
  }

  &__icon {
    color: $app-gray-18;

    &--camera {
      width: em(26);
      min-width: em(26);
      height: em(23);

      &--2 {
        width: em(20);
        min-width: em(20);
        height: em(18);
      }
    }

    &--cross {
      width: em(35);
      min-width: em(35);
      height: em(35);

      &--2 {
        width: em(25);
        min-width: em(25);
        height: em(25);
      }
    }

    &--check {
      width: em(14);
      height: em(10);
    }

    &--false {
      width: em(15);
      height: em(15);
    }
  }
}

.base-button {
  $parent: &;
  width: auto;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 16) em(16, 16);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}
</style>
